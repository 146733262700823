import classNames from "classnames";
import * as classes from "./Paper.css";

export function Paper({
  children,
  header,
  secondary,
  centerX,
  centerY,
  spaceY,
  spaceX,
  fullHeight,
  offsetHeader,
}: {
  children: React.ReactNode;
  header?: React.ReactNode;
  secondary?: boolean;
  centerX?: boolean;
  centerY?: boolean;
  spaceY?: boolean;
  spaceX?: boolean;
  fullHeight?: boolean;
  offsetHeader?: boolean;
}) {
  return (
    <div
      className={classNames(classes.container, {
        [classes.fullHeight]: fullHeight,
        [classes.offsetHeader]: offsetHeader,
      })}
    >
      {header}
      <div
        className={classNames(classes.paper, {
          [classes.secondary]: secondary,
          [classes.centerX]: centerX,
          [classes.centerY]: centerY,
          [classes.spaceY]: spaceY,
          [classes.spaceX]: spaceX,
        })}
      >
        {children}
      </div>
    </div>
  );
}
